import React, { lazy } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import Loadable from "./Loaders/Loadable";
import ExternalView from "./views/external/Views";
// -------------------------------admin imports -------------------------------------------------

const AdminAppsView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminAppsView" /* webpackChunkName: "AdminAppsView Chunk" */
    )
  )
);

const AdminUsersListView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminUsersListView" /* webpackChunkName: "AdminUsersListView Chunk" */
    )
  )
);

const AdminTypesView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminTypesView" /* webpackChunkName: "AdminTypesView Chunk" */
    )
  )
);

const AdminContainersView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminContainersView" /* webpackChunkName: "AdminContainersView Chunk" */
    )
  )
);

const AdminImportView = Loadable(
  lazy(() =>
    import("./views/admin/Import/Import" /* webpackChunkName: "Import Chunk" */)
  )
);

const AdminProductExternalView = Loadable(
  lazy(() =>
    import(
      "./views/admin/ProductExternalView/ProductExternalView" /* webpackChunkName: "Product External view Chunk" */
    )
  )
);

const AdminIntegrationsView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AroIntegration" /* webpackChunkName: "AroIntegration Chunk" */
    )
  )
);

const AdminMigrationView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AroMigrator/AroMigrator" /* webpackChunkName: "AroMigrator Chunk" */
    )
  )
);

const AdminSyncView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AroSync/AroSync" /* webpackChunkName: "AroSync Chunk" */
    )
  )
);

const AdminUsersView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminUsersView" /* webpackChunkName: "AdminUsersView Chunk" */
    )
  )
);

const AdminSettingView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminSettingView" /* webpackChunkName: "AdminSettingView Chunk" */
    )
  )
);

const AdminGroupsListView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminGroupsListView" /* webpackChunkName: "AdminGroupsListView Chunk" */
    )
  )
);

const AdminEmailsListView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminEmailsListView" /* webpackChunkName: "AdminEmailsListView Chunk" */
    )
  )
);

const AdminLanguageView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminLanguageView" /* webpackChunkName: "AdminLanguageView Chunk" */
    )
  )
);

const AdminCommonSettingsView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminCommonSettingsView" /* webpackChunkName: "AdminCommonSettings Chunk" */
    )
  )
);

const AdminUnitView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminUnitView" /* webpackChunkName: "AdminUnitView Chunk" */
    )
  )
);

const AdminSchedularView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminSchedularView" /* webpackChunkName: "AdminSchedularView Chunk" */
    )
  )
);

const AdminInterAppView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminInterAppView/AdminInterApp" /* webpackChunkName: "AdminInterAppView Chunk" */
    )
  )
);

const AdminFileManagementView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminFileManagementView/AdminFileManagement" /* webpackChunkName: "AdminFileManagementView Chunk" */
    )
  )
);

const AdminSystemHealthView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminSystemHealthView/AdminSystemHealth" /* webpackChunkName: "AdminSystemHealthView Chunk" */
    )
  )
);

const AdminDashboardsListView = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminDashboardsView" /* webpackChunkName: "AdminDashboardsListView Chunk" */
    )
  )
);

const GroupDetails = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminGroupsListView/GroupTabDetails" /* webpackChunkName: "GroupDetails Chunk" */
    )
  )
);

//This Folder has new filter logic with relationship
// const AdminViewsViews = Loadable(
//   lazy(() =>
//     import(
//       "./views/admin/AdminViewsView" /* webpackChunkName: "AdminViewsViews Chunk" */
//     )
//   )
// );

//This folder has old view filter logic Which we are using at gantt too
const AdminViewsViews = Loadable(
  lazy(() =>
    import(
      "./views/admin/AdminViewsViewsWithoutRelationship" /* webpackChunkName: "AdminViewsViews Chunk" */
    )
  )
);

const AdminAppLayout = Loadable(
  lazy(() =>
    import(
      "./layouts/AdminAppLayout" /* webpackChunkName: "AdminAppLayout Chunk" */
    )
  )
);

const AdminTypeLayout = Loadable(
  lazy(() =>
    import(
      "./layouts/AdminTypeLayout" /* webpackChunkName: "AdminTypeLayout Chunk" */
    )
  )
);

const AdminContainerLayout = Loadable(
  lazy(() =>
    import(
      "./layouts/AdminContainerLayout" /* webpackChunkName: "AdminContainerLayout Chunk" */
    )
  )
);

const MainLayout = Loadable(
  lazy(() =>
    import("./layouts/MainLayout" /* webpackChunkName: "MainLayout Chunk" */)
  )
);

const AdminLayout = Loadable(
  lazy(() =>
    import("./layouts/AdminLayout" /* webpackChunkName: "AdminLayout Chunk" */)
  )
);

const ExternalLayout = Loadable(
  lazy(() =>
    import(
      "./layouts/ExternalLayout" /* webpackChunkName: "ExternalLayout Chunk" */
    )
  )
);
//--------------- user imports ------------------------------------------------------------------------------

const UserLayout = Loadable(
  lazy(() =>
    import("./layouts/UserLayout" /* webpackChunkName: "UserLayout Chunk" */)
  )
);
const UserContainerLayout = Loadable(
  lazy(() =>
    import(
      "./layouts/UserContainerLayout" /* webpackChunkName: "UserContainerLayout Chunk" */
    )
  )
);

const UserAppsView = Loadable(
  lazy(() =>
    import(
      "./views/user/UserAppsView" /* webpackChunkName: "UserAppsView Chunk" */
    )
  )
);
const UserProfileView = Loadable(
  lazy(() =>
    import(
      "./views/user/UserProfileView" /* webpackChunkName: "UserProfileView Chunk" */
    )
  )
);

const UserViewView = Loadable(
  lazy(() =>
    import(
      "./views/user/UserViewsViews/UserViews" /* webpackChunkName: "UserViewView Chunk" */
    )
  )
);
const UserGroupView = Loadable(
  lazy(() =>
    import(
      "./views/user/UserViewsViews/UserGroupView/GroupView" /* webpackChunkName: "UserGroupedView Chunk" */
    )
  )
);

const UserExternalTypeView = Loadable(
  lazy(() =>
    import(
      "./views/user/UserExternalTypeView" /* webpackChunkName: "UserExternalTypeView Chunk" */
    )
  )
);

const UserContainersView = Loadable(
  lazy(() =>
    import(
      "./views/user/UserContainersView" /* webpackChunkName: "UserContainersView Chunk" */
    )
  )
);
const TypeKanbanView = Loadable(
  lazy(() =>
    import(
      "./views/user/TypeKanbanView" /* webpackChunkName: "TypeKanbanView Chunk" */
    )
  )
);
const TypeGanttView = Loadable(
  lazy(() =>
    import(
      "./views/user/TypeGanttView" /* webpackChunkName: "TypeGanttView Chunk" */
    )
  )
);
const TypeCalendarView = Loadable(
  lazy(() =>
    import(
      "./views/user/TypeCalendarView" /* webpackChunkName: "TypeCalendarView Chunk" */
    )
  )
);
const TypeWikiView = Loadable(
  lazy(() =>
    import(
      "./views/user/TypeWikiView/Wiki" /* webpackChunkName: "TypeWikiView Chunk" */
    )
  )
);

const Planning = Loadable(
  lazy(() =>
    import("./views/user/Planning" /* webpackChunkName: "Planning Chunk" */)
  )
);

const TestManagement = Loadable(
  lazy(() =>
    import(
      "./views/user/TypeTestManagementView/TypeTestManagement" /* webpackChunkName: "Test Management Chunk" */
    )
  )
);

const DocumentView = Loadable(
  lazy(() =>
    import(
      "./views/user/DocumentView/DocumentView" /* webpackChunkName: "DocumentView Chunk" */
    )
  )
);
const Dashboard = Loadable(
  lazy(() =>
    import(
      "./views/user/TypeDashboardView/Dashboard" /* webpackChunkName: "Dashboard Chunk" */
    )
  )
);
const ContainerManagerSettings = Loadable(
  lazy(() =>
    import(
      "./views/user/TypeContainerManagerSettings" /* webpackChunkName: "ContainerManagerSettings Chunk" */
    )
  )
);

const LoginView = Loadable(
  lazy(() =>
    import("./views/auth/LoginView" /* webpackChunkName: "LoginView Chunk" */)
  )
);
const ForgotPasswordView = Loadable(
  lazy(() =>
    import(
      "./views/auth/ForgotPasswordView" /* webpackChunkName: "ForgotPasswordView Chunk" */
    )
  )
);
const ResetPasswordView = Loadable(
  lazy(() =>
    import(
      "./views/auth/ResetPasswordView" /* webpackChunkName: "ResetPasswordView Chunk" */
    )
  )
);
const SuccessMessageView = Loadable(
  lazy(() =>
    import(
      "./views/auth/SuccessMessageView" /* webpackChunkName: "SuccessMessageView Chunk" */
    )
  )
);
const SignupView = Loadable(
  lazy(() =>
    import("./views/auth/SignupView" /* webpackChunkName: "SignupView Chunk" */)
  )
);

const NotFoundView = Loadable(
  lazy(() =>
    import(
      "./views/errors/NotFoundView" /* webpackChunkName: "NotFoundView Chunk" */
    )
  )
);
const NoPermissionView = Loadable(
  lazy(() =>
    import(
      "./views/errors/NoPermissionView" /* webpackChunkName: "NoPermissionView Chunk" */
    )
  )
);

const Handbook = Loadable(
  lazy(() =>
    import("./views/Handbook" /* webpackChunkName: "Handbook Chunk" */)
  )
);

const Wiki = Loadable(
  lazy(() => import("./views/user/Wiki" /* webpackChunkName: "Wiki Chunk" */))
);

const WikiViews = Loadable(
  lazy(() =>
    import("./views/user/Wiki/WikiViews" /* webpackChunkName: "Wiki Chunk" */)
  )
);

const TypeObject = Loadable(
  lazy(() =>
    import(
      "./components/LayoutComponent/Types/DisplayLayout" /* webpackChunkName: "DisplayLayout Chunk" */
    )
  )
);

const TypeObjectExternal = Loadable(
  lazy(() =>
    import(
      "./components/LayoutComponent/ExternalTypes/DisplayLayout" /* webpackChunkName: "DisplayLayout Chunk" */
    )
  )
);

const DisplayLayoutWithoutBackButton = Loadable(
  lazy(() =>
    import(
      "./components/LayoutComponent/Types/DisplayLayoutWithoutBackButton" /* webpackChunkName: "DisplayLayoutWithoutBackButton Chunk" */
    )
  )
);

const TypeObjectViewForTestManagement = Loadable(
  lazy(() =>
    import(
      "./components/UserLayouts/Types/ViewLayoutForTestManagement" /* webpackChunkName: "ViewLayoutForSchedularIssueLink Chunk" */
    )
  )
);

const UserForecastView = Loadable(
  lazy(() =>
    import(
      "./views/user/TypeForecastView" /* webpackChunkName: "UserForecast Chunk" */
    )
  )
);

// const ExternalView = Loadable(
//   lazy(() =>
//     import(
//       "./views/external/Views" /* webpackChunkName: "ExternalView Chunk" */
//     )
//   )
// );

const routes = [
  {
    path: "wiscode/admin/",
    element: <AdminLayout />,
    children: [
      { path: "apps", element: <AdminAppsView /> },
      { path: "account", element: <UserProfileView /> },
      { path: "users", element: <AdminUsersListView /> },
      { path: "emails", element: <AdminEmailsListView /> },
      { path: "languages", element: <AdminLanguageView /> },
      { path: "unitS", element: <AdminUnitView /> },
      { path: "schedular", element: <AdminSchedularView /> },
      { path: "interapp", element: <AdminInterAppView /> },
      { path: "commonSettings", element: <AdminCommonSettingsView /> },
      { path: "system-health", element: <AdminSystemHealthView /> },
      { path: "file-management", element: <AdminFileManagementView /> },
      { path: "groups", element: <AdminGroupsListView /> },
      { path: "group/:groupId", element: <GroupDetails /> },
      {
        // path: 'app/:id/',
        path: "app/:appId/",
        element: <AdminAppLayout />,
        children: [
          { path: "types", element: <AdminTypesView /> },
          { path: "containers", element: <AdminContainersView /> },
          { path: "integrations", element: <AdminIntegrationsView /> },
          { path: "migration", element: <AdminMigrationView /> },
          { path: "sync", element: <AdminSyncView /> },
          { path: "import", element: <AdminImportView /> },
          { path: "dashboards", element: <AdminDashboardsListView /> },
          { path: "settings", element: <AdminSettingView /> },
          {
            path: "productExternalViews",
            element: <AdminProductExternalView />,
          },
          { path: "views", element: <AdminViewsViews /> },
          { path: "users", element: <AdminUsersView /> },
          {
            path: "type/:typeId",
            element: <AdminTypeLayout />,
          },
          {
            path: "container/:containerId",
            element: <AdminContainerLayout />,
          },
        ],
      },
    ],
  },
  {
    path: "wiscode/user/",
    element: <UserLayout />,
    children: [
      { path: "apps", element: <UserAppsView /> },
      { path: "profile", element: <UserProfileView /> },
      {
        path: "wiki/",
        element: <Wiki />,
        children: [{ path: ":id", element: <WikiViews /> }],
      },
      {
        path: "app/:appId/",
        element: <Outlet />,
        children: [
          {
            path: "container/:containerId/:containerName/:containerInstanceId/",
            element: <UserContainerLayout />,
            children: [
              {
                path: "group/:groupId/type/:typeId/view/:viewId",
                element: <UserViewView />,
              },
              {
                path: "group/:groupId",
                element: <UserGroupView />,
              },
              {
                path: "group/:groupId/type/:typeId/viewext/:viewId",
                element: <UserExternalTypeView />,
              },
              {
                path: "group/:groupId/type/:typeId/:typeName/view/:viewId/:objectId",
                element: <TypeObject />,
              },
              {
                path: "group/:groupId/type/:typeId/:typeName/viewext/:viewId/:objectId",
                element: <TypeObjectExternal />,
              },
              {
                path: "type/:typeId/:typeName/:objectId",
                element: <DisplayLayoutWithoutBackButton />,
              },
              {
                path: "type/:typeId/:typeName/:objectId/testManagement",
                element: <TypeObjectViewForTestManagement />,
              },
              { path: "kanban", element: <TypeKanbanView /> },
              { path: "gantt", element: <TypeGanttView /> },
              { path: "Planning", element: <Planning /> },
              { path: "TestManagement", element: <TestManagement /> },
              { path: "Forecast", element: <UserForecastView /> },
              {
                path: "group/:groupId/type/:typeId/:typeName/view/:viewId/:objectId/documentvu",
                element: <DocumentView />,
              },
              { path: "calendar", element: <TypeCalendarView /> },
              { path: "dashboard", element: <Dashboard /> },
              { path: "wiki", element: <TypeWikiView /> },
              {
                path: "containermanagersettings",
                element: <ContainerManagerSettings />,
              },
            ],
          },
          { path: "", element: <UserContainersView /> },
        ],
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "wiscode/external/",
    element: <ExternalLayout />,
    children: [
      {
        path: "app/:appId/container/:containerId/containerInstanceId/:containerInstanceId",
        element: <ExternalView />,
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "wiscode/login", element: <LoginView /> },
      { path: "wiscode/sessiontimeout/login", element: <LoginView /> },
      { path: "wiscode/forgotpassword", element: <ForgotPasswordView /> },
      {
        path: "wiscode/resetPassword/:pwdToken/:userId",
        element: <ResetPasswordView />,
      },

      {
        path: "wiscode/resetpassword/successful",
        element: <SuccessMessageView />,
      },
      {
        path: "wiscode/signup/:signuptoken",
        element: <SignupView />,
      },
      { path: "wiscode/nopermission", element: <NoPermissionView /> },
      { path: "wiscode/handbook", element: <Handbook /> },
      { path: "404", element: <NotFoundView /> },
      { path: "", element: <Navigate to="/wiscode/login" /> },
    ],
  },
];

export default routes;
